/* eslint-disable */

import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import SideBox from "../pages/SideBox";
import Modal from "../pages/Modal";
import Footer from "./Footer";

// css
import "../css/reset.css";
import "../css/App.css";
import "../css/App.font.css";
import "../css/common.css";

/*
  Main 에서 element를 App 컴포넌트로 감싸져 있는 라우터는 
  Outlet 컴포넌트를 거쳐서 렌더링 됨      
*/

function App() {
  const [modalOpen, setModalOpen] = useState(false);
  // const modalBackground = useRef();

  const modalOpenFunc = () => {
    // console.log("모달킴");
    setModalOpen(true);
  };

  const modalCloseFunc = () => {
    // console.log("모달끔");
    setModalOpen(false);
  };

  const location = useLocation();

  const getMetaData = () => {
    switch (location.pathname) {
      // company
      case "/introduction":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description:
            "(주)레인보우브레인은 인공지능 및 업무자동화 분야 솔루션 개발 및 구축 전문 기업입니다.",
        };
      case "/support":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description:
            "인공지능 및 업무자동화 분야 솔루션과 관련하여 궁금하신 점이 있다면 문의해주시기 바랍니다.",
        };

      // business
      case "/rpa":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description:
            "업무자동화의 대표격인 로봇 소프트웨어 RPA를 소개합니다.",
        };
      case "/rpaportal":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description:
            "RPA를 보다 쉽고 편리하고 운영, 관리할 수 있는 통합 관리 플랫폼입니다.",
        };
      case "/eurekagenai":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description:
            "지식 기반 데이터로 업무를 지원하는 기업 특화 AI 솔루션을 소개합니다.",
        };
      case "/aisolution":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description:
            "자사가 개발한 인공지능 및 업무 자동화 분야 솔루션을 소개합니다.",
        };

      // media
      case "/news":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description: "AI 소식, 최신 기술 등 관련된 최신 뉴스를 제공합니다.",
        };
      case "/case":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description:
            "자동화 업무를 위해 기업이 어떻게 RPA를 활용하고 있는지 알아봅니다.",
        };
      case "/conference":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description:
            "비즈니스를 위한 생성형 AI에 대한 다양한 정보를 제공합니다.",
        };
      case "/newsletter":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description: "AI 소식, 최신 기술 등 관련된 최신 뉴스를 제공합니다.",
        };
      case "/newsletter/detailpage":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description: "AI 소식, 최신 기술 등 관련된 최신 뉴스를 제공합니다.",
        };
      case "/casebook":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description: "AI 소식, 최신 기술 등 관련된 최신 뉴스를 제공합니다.",
        };

      // partners
      case "/partners":
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description:
            "자사의 파트너를 소개하고, 자사 제품의 서비스 소개서를 제공합니다.",
        };

      default:
        return {
          title: "레인보우브레인 | 지능형자동화 전문기업",
          description:
            "레인보우브레인은 지능형자동화 전문기업입니다. 비즈니스를 위한 필수 선택, 생성형 AI LLM · RAG · RPA 솔루션을 제공합니다.",
        };
    }
  };

  const { title, description } = getMetaData();

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="RPA" />
        {/* <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="레인보우브레인은 지능형자동화 전문기업입니다. 비즈니스를 위한 필수 선택, 생성형 AI LLM · RAG · RPA 솔루션을 제공합니다."
        /> */}
      </Helmet>

      {/* Nav */}
      <Navbar className="nav" />

      {/* body */}
      <div className="body">
        <Outlet />
      </div>
      <div className={modalOpen ? "side_box hidden" : "side_box"}>
        <SideBox onClick={modalOpenFunc} />
      </div>
      {modalOpen && <Modal onClose={modalCloseFunc} />}

      <Footer className="footer" />
    </>
  );
}

export default App;
