import React from "react";
import { Helmet } from "react-helmet";
import ScrollTop from "./ScrollTop";
import Content from "../components/ContentHeader.js";
import Route from "../components/Route.js";
import Slogan from "./Slogan";

// css
import "../css/Partners.css";

// img
import casebook from "../assets/img/casebook.png";

function Casebook() {
  return (
    <div className="casebook_page">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="우리 회사에도 적용할 수 있을까? 실제 도입 사례로 확인해보세요."
        ></meta>
      </Helmet>

      <Content
        title="CASEBOOK"
        title1={
          <p className="content-title1">
            우리 회사에도 적용할 수 있을까? 실제 도입 사례로 확인해보세요
          </p>
        }
      />

      <Route route1="MEDIA" route2="CASEBOOK"></Route>

      <div className="content_bg">
        <div className="container">
          <div className="casebook_wrap">
            <div className="casebook_left">
              <div className="left_img">
                <img src={casebook} alt="메인배경" />
              </div>
              <p className="contents2">
                🔎 RAG(검색증강생성, Retrieval-Augmented Generation)은 외부
                지식(문서, 데이터베이스 등)을 검색해 활용함으로써, 보다 정확하고
                신뢰도 높은 답변을 생성하는 생성형 AI 기술입니다.
                <br />
                <br />이 사례집은 다양한 산업에서 RAG를 실제로 적용한 실전 구축
                사례와 기술 전략을 담고 있습니다.
                <br />
                <br />
                📌 이 사례집을 통해 확인할 수 있는 내용
                <br />
                <br />
                ✔ RAG 기술 개요 – 왜 필요한가? 어떻게 작동하는가?
                <br />
                ✔ AI 검색 정확도 향상 전략 – 문맥 분할, 테이블 전처리, 프롬프트
                설계
                <br />
                ✔ 금융·제조·에너지 업계의 실제 구축 사례
                <br />
                ✔ ‘유레카RAG’ 플랫폼의 구성과 핵심 기능
                <br />
                ✔ 생성형 AI 핵심 용어 설명 수록 – RAG, 임베딩, 프롬프트
                엔지니어링 등
                <br />
                ✔ 파일 수집부터 운영까지, AI 도입의 전 과정
                <br />
                <br />
                📩 입력하신 이메일로 사례집 다운로드 링크가 자동 발송됩니다.
                <br />
                메일이 도착하지 않았다면, 스팸함 또는 프로모션함을 확인해
                주세요.
                <br />
                <br />
                🔐 단, 마케팅 정보 수신에 동의하신 분에 한해 발송됩니다.
                <br />
                <br />
                🚀 지금 무료 사례집을 받아보고, RAG 기반 AI 도입의 실전 전략을
                확인하세요!
              </p>
            </div>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLScG3ni8Z_ZxQXKAu91MnY8jM-mfW2sH5xIFZJRWo66cX6nkEA/viewform"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="Google Form"
            />
          </div>
        </div>
      </div>

      <Slogan containerPadding="5vw 0" />
    </div>
  );
}

export default Casebook;
