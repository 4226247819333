import { Helmet } from "react-helmet";
import ScrollTop from "./ScrollTop";
import VisualSlide from "./VisualSlide";
import News from "./News";
import MainSlider from "./MainSlider";
import Case from "./Case";
import Client from "./Client";
import Slogan from "./Slogan";
import MainBanner from "./MainBanner";
import Popup from "./Popup";

// css
import "../css/MainPage.css";

function MainPage() {
  return (
    <>
      <ScrollTop />
      <Popup />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>
      <VisualSlide />

      <News />
      <MainSlider />
      <Case />
      <Client />

      {/* <CustomerCase /> */}

      <Slogan />
      <MainBanner />
    </>
  );
}

export default MainPage;
