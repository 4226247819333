import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCase } from "../api/api.js";

// css
import "../css/Example.css";

// img
import buttonArrow from "../assets/img/button_arrow_icon.png";
import titleIcon from "../assets/img/icon_title_text.png";

function Example() {
  const [data, setData] = useState([]);

  const [maxItems, setMaxItems] = useState();

  const handleClickDetail = (item) => {
    let blogUrl = item.blogUrl;
    window.open(blogUrl, "_blank");
    // console.log(item);
  };

  const handleLoad = async () => {
    const news = await getCase();
    // console.log("case :: ", news);
    setMaxItems(8);
    setData(news);
  };

  useEffect(() => {
    handleLoad();
  }, []);

  return (
    <div className="example_container">
      <div className="container">
        <div className="example_wrap">
          <div className="title">
            <img src={titleIcon} alt="titleIcon" />
            <span className="heading1">구축 사례</span>
          </div>

          <ul className="contents1 ex_list">
            {data.slice(0, maxItems).map((item, index) => (
              <li className="news" key={index}>
                <div
                  className="thum_hover"
                  key={item.id}
                  onClick={() => handleClickDetail(item)}
                >
                  <img src={item.fileUrl} alt="썸네일" />
                </div>

                <div className="news_thumbnail">
                  <p className="contents2 news_title">{item.title}</p>
                  {/* <p className="contents2 news_content">
                      {item.summary_content
                      ? item.summary_content
                      : item.content.replace(/<\/?[^>]+(>|$)/g, "")}
                    </p> */}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <Link to="/media/case">
            <button className="blue_btn button1">
              사례 더 보러가기
              <img src={buttonArrow} alt="사례 더 보러가기" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Example;
