// npm install --save react-toastify ( 알림창 toast )

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { inquirysCreate, sendMail, getFaqs, getFaqsCount } from "../api/api.js";
import ScrollTop from "./ScrollTop";
import Route from "../components/Route";
import Kakao from "../components/Kakao";
import Content from "../components/ContentHeader";
import Paging from "../components/Paging.js";

// css
import "react-toastify/dist/ReactToastify.css";
import "../css/SupportPage.css";

// img
import dropdown from "../assets/img/dropdown.png";

function SupportPage() {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 5;

  const [activeFaq, setActiveFaq] = useState(null);
  const [faqList, setFaqList] = useState([]);

  const navigate = useNavigate();

  const toggleFaq = (index) => {
    setActiveFaq(activeFaq === index ? null : index);
  };

  // handleSearchSubmit : 검색하기 버튼 클릭시 실행되는 함수

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    console.log(e.target.search_keyword.value);
    setSearchKeyword(e.target.search_keyword.value);
  };

  // handlePaging :  페이징 버튼 클릭시 실행되는 함수
  const handlePaging = (page) => {
    setPage(page); // 페이지 번호
    setActiveFaq(null); // 페이지 넘어가면 펼쳐져있던 내용 접히게.

    if (page === 1) {
      setOffset(0);
    } else {
      setOffset((page - 1) * limit);
    }
  };

  // radio버튼
  // const option = ["일반문의", "교육신청"];
  // const [selectedOption, setSelectedOption] = useState(option[0]);
  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  // checkbox버튼
  const checkOption = [
    "검색",
    "뉴스레터",
    "블로그",
    "유튜브",
    "페이스북",
    "기타"
  ];
  const [checkedItems, setCheckedItems] = useState({});

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems({ ...checkedItems, [name]: checked });
  };

  // 연락처 입력시 실행되는 함수 (숫자만 입력하게 하는 함수, 11자리까지만)
  const [phone, setPhone] = useState("");
  function handlePhoneChange(e) {
    const inputVal = e.target.value.replace(/[^0-9]/g, "").slice(0, 11);
    setPhone(inputVal);
  }

  // 성함 입력시 실행되는 함수
  const [name, setName] = useState("");
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  // 회사,소속 입력시 실행되는 함수
  const [company, setCompany] = useState("");
  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  // 이메일 입력시 실행되는 함수
  const [email, setEmail] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // 문의내용 입력시 실행되는 함수
  const [content, setContent] = useState("");
  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  // 동의하기 버튼
  const [agreement, setAgreement] = useState(false);
  const handleAgreementChange = (e) => {
    setAgreement(!agreement);
  };

  // console.log(selectedOption);
  console.log(checkedItems);
  console.log(name);
  console.log(company);
  console.log(phone);
  console.log(email);
  console.log(content);
  console.log(agreement);

  // 문의하기 버튼 클릭 하면 실행되는 함수.
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("이름을 입력해 주세요.");
      return;
    } else if (!company) {
      toast.error("회사/소속을 입력해 주세요.");
      return;
    } else if (!phone) {
      toast.error("연락처를 입력해 주세요.");
      return;
    } else if (!email) {
      toast.error("이메일을 입력해 주세요.");
      return;
    } else if (!email || !/\S+@\S+\.\S+/.test(email)) {
      toast.error("올바른 이메일 형식을 입력해 주세요.");
      return;
    } else if (!content) {
      toast.warn("문의 내용을 입력해 주세요.");
      return;
    } else if (!checkedItems || Object.keys(checkedItems).length === 0) {
      toast.warn("유입 경로를 선택해 주세요.");
      return;
    }

    const checkedItemKeys = Object.keys(checkedItems).filter(
      (key) => checkedItems[key] !== "{}"
    );
    const checkedItemString = checkedItemKeys.join(", ");

    const uploadData = {
      name: name,
      company: company,
      phone: phone,
      email: email,
      // content: content,
      content: `<p>${content.replace(
        /\n/g,
        "<br/>"
      )} <br/><br/> ● 유입경로 : ${checkedItemString}</p>`
      //  ● 문의목적 : ${selectedOption} <br/>
    };

    let result = await inquirysCreate(uploadData);
    // console.log(result);

    if (result !== null) {
      toast("문의사항이 접수되었습니다.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: function () {
          navigate("/");
        }
      });
      setFormSubmitted(true);

      // 문의를 등록한 사용자한테 가는 메일아님, 레인보우 관리자에게 발송하는 메일링.
      let resultSendMail = await sendMail(uploadData);

      console.log(resultSendMail);
    }
  };

  const handleLoad = async (options) => {
    const data = await getFaqs(options);
    setFaqList(data);

    const faqsCount = await getFaqsCount(options);
    setTotalItemsCount(faqsCount.length);
  };

  useEffect(() => {
    handleLoad({
      open: "1",
      selectedOption: "updatedAt",
      search: searchKeyword,
      startDate: "",
      endDate: "",
      offset: offset,
      limit: limit
    });
  }, [searchKeyword, offset, limit]);
  // [selectedOption, searchKeyword, offset, limit]

  return (
    <div className="intro_wrap">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="인공지능 및 업무자동화 분야 솔루션과 관련하여 궁금하신 점이 있다면 문의해주시기 바랍니다."
        ></meta>
      </Helmet>

      <Content title="RAINBOW BRAIN"></Content>
      <div className="header_tab">
        <div className="container sub_title4">
          <Link to="/company/introduction" className="intro">
            <span>INTRODUCTION</span>
          </Link>
          <Link to="/company/support" className="support header_active">
            <span>SUPPORT</span>
          </Link>
        </div>
      </div>

      <Route route1="RAINBOW BRAIN" route2="SUPPORT"></Route>
      <div className="content_bg">
        <div className="container">
          {/* 문의하기 */}
          <div className="contact_wrap">
            <div className="support_header">
              <h2 className="heading1">CONTACT</h2>
              <p className="sub_title3">문의하기</p>
            </div>
            <div className="support_box">
              <ul className="contact_list">
                {/* <li className='purpose'>
                  <p className='sub_title4'>문의목적</p>
                  <div className='purpose_checkbox contents1'>
                    {option.map((item) => (
                      <label key={item}>
                        <input
                          type='radio'
                          value={item}
                          checked={selectedOption === item}
                          onChange={handleOptionChange}
                          name={item}
                        />
                        {item}
                        <span className='radiomark'></span>
                      </label>
                    ))}
                  </div>
                </li> */}

                <li className="name">
                  <p className="sub_title4">
                    성함<span className="red">*</span>
                  </p>
                  <input
                    className="contents1 contact_input"
                    type="text"
                    name="name"
                    value={name}
                    onChange={handleNameChange}
                    required
                  />
                </li>
                <li className="company">
                  <p className="sub_title4">
                    회사/소속/직급<span className="red">*</span>
                  </p>
                  <input
                    className="contents1 contact_input"
                    type="text"
                    name="company"
                    value={company}
                    onChange={handleCompanyChange}
                    required
                  />
                </li>
                <li className="phone">
                  <p className="sub_title4">
                    연락처<span className="red">*</span>
                  </p>
                  <input
                    className="contents1 contact_input"
                    type="text"
                    placeholder='"-"없이 숫자만 입력하시기 바랍니다.'
                    onChange={handlePhoneChange}
                    value={phone}
                    required
                  />
                </li>
                <li className="email">
                  <p className="sub_title4">
                    이메일<span className="red">*</span>
                  </p>
                  <input
                    className="contents1 contact_input"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="example@email.com"
                    onChange={handleEmailChange}
                    required
                  />
                </li>
                <li className="course sub contents1">
                  <p className="sub_title4">
                    유입 경로 (중복 선택 가능)<span className="red">*</span>
                  </p>
                  {checkOption.map((option) => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        name={option}
                        checked={checkedItems[option] || false}
                        onChange={handleChange}
                      />
                      {option}
                      <span className="checkmark"></span>
                    </label>
                  ))}
                </li>

                <li className="content">
                  <p className="sub_title4">
                    문의 내용<span className="red">*</span>
                  </p>
                  <textarea
                    style={{ height: "130px" }}
                    className="contents1"
                    cols="30"
                    rows="10"
                    name="content"
                    value={content}
                    onChange={handleContentChange}
                    required
                  ></textarea>
                </li>
                <li className="personal">
                  <p className="sub_title4">개인정보 수집 및 이용</p>
                  <div className="personal_bg">
                    <p className="contents1">
                      온라인 문의에 대한 답변을 드리기 위해 최소한의 개인정보를
                      수집하고 이용합니다.
                      <br />
                      수집한 정보는 위 목적 외 이용되지 않으며, 해당 답변 후
                      즉시 파기합니다.
                      <br />
                      <br />
                      (주)레인보우브레인의 개인정보 처리 방침을 확인했으며,
                      개인정보 수집 및 이용에 동의하십니까?
                    </p>
                  </div>
                  <div className="contents1">
                    <label>
                      <input
                        type="checkbox"
                        checked={agreement}
                        onChange={handleAgreementChange}
                        name="agreement"
                      />
                      동의하기
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </li>
              </ul>
              <div className="contact_footer">
                <p className="contents1">
                  답변이 오지 않거나 추가 문의가 필요한 경우,&nbsp;
                  <span>contact@rbrain.co.kr</span>로 문의하시기 바랍니다.
                </p>
                <button
                  type="submit"
                  className="blue_btn button1"
                  onClick={handleSubmit}
                  disabled={!agreement || formSubmitted} // 동의하지 않거나 양식이 제출된 경우 비활성화
                >
                  문의내용 등록하기
                </button>
              </div>
            </div>
          </div>

          {/* FAQ */}
          <div className="faq_wrap">
            <div className="support_header">
              <h2 className="heading1">FAQ</h2>
              <p className="sub_title3">자주하는 질문</p>
            </div>
            <form onSubmit={handleSearchSubmit}>
              <div className="faq_search">
                <input
                  className="contents1"
                  type="text"
                  name="search_keyword"
                  placeholder="내용에 포함된 단어를 입력하여 검색합니다."
                />
                <button className="blue_btn button2">검색하기</button>
              </div>
            </form>

            {faqList.map((faq, index) => (
              <div key={faq.id} className="faq_list">
                <div className="faq_title" onClick={() => toggleFaq(index)}>
                  <p className="sub_title4">{faq.title}</p>
                  <img
                    src={dropdown}
                    className={`dropdown_icon ${
                      activeFaq === index ? "icon_hidden" : ""
                    }`}
                    alt="열기"
                  />
                  <img
                    src={dropdown}
                    className={`dropup_icon ${
                      activeFaq === index ? "" : "icon_hidden"
                    }`}
                    alt="닫기"
                  />
                </div>
                <div
                  className={`faq_content ${
                    activeFaq === index ? "active" : ""
                  }`}
                >
                  <p className="contents1">
                    {faq.content.replace(/(<([^>]+)>)/gi, "")}
                  </p>
                </div>
              </div>
            ))}

            <div className="pagination">
              {/* 페이징 컴포넌트 */}
              <Paging
                items={faqList}
                page={page}
                handlePaging={handlePaging}
                totalItemsCount={totalItemsCount}
                itemsCountPerPage={limit}
                pageRangeDisplayed={3}
              />
            </div>
          </div>

          {/* MAP */}
          <div className="kakao_map">
            <div className="support_header">
              <h2 className="heading1">MAP</h2>
              <p className="sub_title3">오시는 길</p>
            </div>
            <div className="support_box">
              <Kakao />
              <p className="contents1">
                서울시 영등포구 은행로35 기계회관 본관 4층(07238)​
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeButton={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default SupportPage;
