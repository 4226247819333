import { NavLink } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

// css
import "../css/Navbar.css";

// img
import logoImg from "../assets/img/logo_white.png";
import dotImg from "../assets/img/dot.png";
import openImg from "../assets/img/mobile_menu_open.png";
import closeImg from "../assets/img/mobile_menu_close.png";

function Navbar() {
  const [isMobileWrapOpen, setIsMobileWrapOpen] = useState(false);

  const closeMobileWrap = () => {
    setIsMobileWrapOpen(false);
  };

  const closeMobileMenu = () => {
    navRef.current.classList.remove("open");
    setIsMobileWrapOpen(false);
  };

  const navRef = useRef(null);
  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 50 && !isMobileWrapOpen) {
        navRef.current.classList.add("scrolled");
      } else {
        navRef.current.classList.remove("scrolled");
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMobileWrapOpen]);

  return (
    <header>
      <nav id="navigation" ref={navRef}>
        <div className="container">
          <NavLink to="/" className="logo"></NavLink>
          <button
            type="button"
            aria-label="mobile menu"
            className="nav-toggle"
            onClick={() => setIsMobileWrapOpen(!isMobileWrapOpen)}
          >
            <img src={openImg} alt="오픈버튼 이미지" />
          </button>

          <ul className="nav_menu">
            <li>
              <NavLink to="/" className="contents2">
                RAINBOW BRAIN
              </NavLink>
            </li>
            <li>
              <NavLink to="/" className="contents2">
                BUSINESS
              </NavLink>
            </li>
            <li>
              <NavLink to="/" className="contents2">
                MEDIA
              </NavLink>
            </li>
            <li>
              <NavLink to="/" className="contents2">
                PARTNERS
              </NavLink>
            </li>

            <li className="dropdown_menu contents2">
              <div className="dropdown_menu_wrap">
                <div>
                  <NavLink to="/company/introduction" className="navlink">
                    INTRODUCTION
                  </NavLink>
                  <NavLink to="/company/support" className="navlink">
                    SUPPORT
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/business/aisolution" className="navlink">
                    AI SOLUTION
                  </NavLink>
                  <NavLink to="/business/eurekagenai" className="navlink">
                    EurekaGenAI
                  </NavLink>
                  <NavLink to="/business/rpa" className="navlink">
                    RPA
                  </NavLink>
                  <NavLink to="/business/rpaportal" className="navlink">
                    RPA PORTAL
                  </NavLink>
                  <NavLink to="/business/voucher" className="navlink">
                    VOUCHER
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/media/news" className="navlink">
                    NEWS
                  </NavLink>
                  <NavLink to="/media/case" className="navlink">
                    USE CASE
                  </NavLink>
                  <NavLink to="/media/conference" className="navlink">
                    CONFERENCE
                  </NavLink>
                  <NavLink to="/media/newsletter" className="navlink">
                    NEWSLETTER
                  </NavLink>
                  {/* <NavLink to="/media/casebook" className="navlink">
                    CASEBOOK
                  </NavLink> */}
                </div>
                <div>
                  <NavLink to="/partners/partners" className="navlink">
                    PARTNERS
                  </NavLink>
                </div>
              </div>
            </li>
          </ul>

          <div
            className={`mobile_wrap ${isMobileWrapOpen ? "open" : ""}`}
            onClick={closeMobileMenu}
          >
            <div className="mobile_header">
              <NavLink to="/" className="mobile_logo">
                <img src={logoImg} alt="로고 이미지" />
              </NavLink>

              <button className="close-button" onClick={closeMobileWrap}>
                <img src={closeImg} alt="닫기버튼 이미지" />
              </button>
            </div>

            <ul>
              <li>
                <NavLink to="#" className="heading2">
                  RAINBOW BRAIN
                </NavLink>
                <div className="sub_title2">
                  <NavLink
                    to="/company/introduction"
                    className="navlink"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> INTRODUCTION
                  </NavLink>
                  <NavLink
                    to="/company/support"
                    className="navlink sub_border"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> SUPPORT
                  </NavLink>
                </div>
              </li>
              <li>
                <NavLink to="#" className="heading2">
                  BUSINESS
                </NavLink>
                <div className="sub_title2">
                  <NavLink
                    to="/business/aisolution"
                    className="navlink"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> AI SOLUTION
                  </NavLink>
                  <NavLink
                    to="/business/eurekagenai"
                    className="navlink"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> EurekaGenAI
                  </NavLink>
                  <NavLink
                    to="/business/rpa"
                    className="navlink"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> RPA
                  </NavLink>

                  <NavLink
                    to="/business/rpaportal"
                    className="navlink"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> RPA PORTAL
                  </NavLink>
                  <NavLink
                    to="/business/voucher"
                    className="navlink sub_border"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> VOUCHER
                  </NavLink>
                </div>
              </li>
              <li>
                <NavLink to="#" className="heading2">
                  MEDIA
                </NavLink>
                <div className="sub_title2">
                  <NavLink
                    to="/media/news"
                    className="navlink"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> NEWS
                  </NavLink>
                  <NavLink
                    to="/media/case"
                    className="navlink"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> USE CASE
                  </NavLink>
                  <NavLink
                    to="/media/conference"
                    className="navlink"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> CONFERENCE
                  </NavLink>
                  <NavLink
                    to="/media/newsletter"
                    className="navlink sub_border"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> NEWSLETTER
                  </NavLink>
                  {/* <NavLink
                    to="/media/casebook"
                    className="navlink sub_border"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> CASEBOOK
                  </NavLink> */}
                </div>
              </li>
              <li>
                <NavLink to="#" className="heading2">
                  PARTNERS
                </NavLink>
                <div className="sub_title2">
                  <NavLink
                    to="/partners/partners"
                    className="navlink"
                    onClick={closeMobileMenu}
                  >
                    <img src={dotImg} alt="도트 이미지" /> PARTNERS
                  </NavLink>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
