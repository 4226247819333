import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { getCase } from "../api/api.js";
import ScrollTop from "./ScrollTop";
// import SideBox from "./SideBox.js";
import Content from "../components/ContentHeader";
import Route from "../components/Route";

// css
import "../css/Example.css";
import "../css/CasePage.css";

import main_thumb1 from "../assets/img/no_image.png";
import { constructions_click } from "../api/Api_newslettermanage.js";

function CasePage() {
  const [data, setData] = useState([]);

  const [maxItems, setMaxItems] = useState(4);

  // handleClick : 카드 클릭하면 실행되는 함수. -> 레인보우브레인 블로그로 새창띄움.
  const handleClickDetail = (item) => {
    // console.log("casepage :: ", item);
    const currentCount = item.click_count ? parseInt(item.click_count, 10) : 0;
    const uploadData = {
      click_count: currentCount + 1,
    };
    constructions_click(item.id, uploadData);
    let blogUrl = item.blogUrl;
    window.open(blogUrl, "_blank");
    // console.log(item);
  };

  const handleLoad = async () => {
    const news = await getCase();
    // console.log("구축사례 :: ", news);
    setData(news);
  };

  const handleLoadMore = () => {
    setMaxItems((prevMaxItems) => {
      if (window.innerWidth >= 1023 && window.innerWidth <= 1280) {
        return prevMaxItems + 3;
      } else {
        return prevMaxItems + 4;
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1023 && window.innerWidth <= 1280) {
        setMaxItems(3);
      } else {
        setMaxItems(8);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleLoad();
  }, []);

  return (
    <div className="case_page">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="자동화 업무를 위해 기업이 어떻게 RPA를 활용하고 있는지 알아봅니다."
        ></meta>
      </Helmet>

      <Content
        title="USE CASE"
        title1="자동화 업무를 위해 기업이 어떻게 생성형 AI와 RPA를 활용하고 있는지 알아보세요"
      ></Content>

      <Route route1="MEDIA" route2="USE CASE"></Route>
      <div className="content_bg">
        <div className="container">
          <div className="example_wrap">
            <ul className="contents1 ex_list" style={{ paddingTop: "0px" }}>
              {data.slice(0, maxItems).map((item, index) => (
                <li className="news" key={index}>
                  <div
                    className="thum_hover"
                    key={item.id}
                    onClick={() => handleClickDetail(item)}
                  >
                    <img
                      src={item.fileUrl ? item.fileUrl : main_thumb1}
                      alt="썸네일"
                    />
                  </div>

                  <div className="news_thumbnail">
                    <p className="contents2 news_title">{item.title}</p>
                    {/* <p className="contents2 news_content">
                      {item.summary_content
                      ? item.summary_content
                      : item.content.replace(/<\/?[^>]+(>|$)/g, "")}
                    </p> */}
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="contact_footer">
            <button
              type="button"
              className="blue_btn button1"
              onClick={handleLoadMore}
            >
              더보기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CasePage;
