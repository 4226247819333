// 개발 로컬
// const BASE_URL = "http://192.168.0.137:8080";

// 구버전 s3 서버
// const BASE_URL =
// "http://rainbow-brain-backend-server-env.eba-23au4jxd.ap-northeast-2.elasticbeanstalk.com";

// 현재 ec2 서버 = server.tsb.today?
// const BASE_URL = "http://192.168.0.236:8080";
const BASE_URL = "https://server.tsb.today";

export { BASE_URL };
